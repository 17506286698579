<script setup lang="ts">
import { Dialog, DialogPanel, TransitionChild, TransitionRoot } from "@headlessui/vue";
import AppLogo from "@/layouts/partials/marketing/AppLogo.vue";
import SideBarItem from "./SideBarItem.vue";
import HelpCenter from "@/components/dashboard/support/HelpCenter.vue";
import type { NavigationItems } from "@/types";

interface Props {
  navigation: NavigationItems;
  subNavigation: NavigationItems;
  sidebarOpen: boolean;
}

interface Emit {
  (e: "toggleMobileSide", value: boolean): void;
}

const emit = defineEmits<Emit>();
defineProps<Props>();

const closeSideBar = () => {
  emit("toggleMobileSide", false);
};
</script>

<template>
  <TransitionRoot as="template" :show="sidebarOpen">
    <Dialog as="div" class="relative z-50 xl:hidden" @close="closeSideBar">
      <TransitionChild
        as="template"
        enter="transition-opacity ease-linear duration-300"
        enter-from="opacity-0"
        enter-to="opacity-100"
        leave="transition-opacity ease-linear duration-300"
        leave-from="opacity-100"
        leave-to="opacity-0"
      >
        <div class="fixed inset-0 bg-black-gray/80" />
      </TransitionChild>

      <div class="fixed inset-0 flex">
        <TransitionChild
          as="template"
          enter="transition ease-in-out duration-300 transform"
          enter-from="-translate-x-full"
          enter-to="translate-x-0"
          leave="transition ease-in-out duration-300 transform"
          leave-from="translate-x-0"
          leave-to="-translate-x-full"
        >
          <DialogPanel class="relative mr-16 flex w-full max-w-xs flex-1">
            <TransitionChild
              as="template"
              enter="ease-in-out duration-300"
              enter-from="opacity-0"
              enter-to="opacity-100"
              leave="ease-in-out duration-300"
              leave-from="opacity-100"
              leave-to="opacity-0"
            >
              <div class="absolute left-full top-0 flex w-16 justify-center pt-5">
                <button type="button" class="-m-2.5 p-2.5" @click="closeSideBar">
                  <span class="sr-only">Close sidebar</span>
                  <Icon name="fa6-solid:xmark" class="h-6 w-6 text-white" aria-hidden="true" />
                </button>
              </div>
            </TransitionChild>
            <div class="flex grow flex-col gap-y-5 overflow-y-auto bg-white px-6 pb-4">
              <div class="flex h-16 shrink-0 items-center">
                <AppLogo />
              </div>
              <CreditsCTA reduced class="sm:hidden" @click="closeSideBar" />
              <nav class="flex flex-1 flex-col -mx-2">
                <ul role="list" class="flex flex-1 flex-col gap-y-7">
                  <li>
                    <ul role="list" class="space-y-1">
                      <template v-for="item in navigation" :key="item.name">
                        <SideBarItem :item="item" @click="closeSideBar" />
                      </template>
                    </ul>
                  </li>
                  <div class="mt-auto">
                    <ul role="list" class="space-y-1">
                      <template v-for="item in subNavigation" :key="item.name">
                        <SideBarItem :item="item" @click="closeSideBar" />
                      </template>
                    </ul>
                  </div>
                </ul>
                <HelpCenter class="mt-2" />
              </nav>
            </div>
          </DialogPanel>
        </TransitionChild>
      </div>
    </Dialog>
  </TransitionRoot>
</template>
